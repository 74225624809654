import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Container,
    FormField,
    Header,
    Input,
    SpaceBetween,
    Table,
} from '@amzn/awsui-components-react';
import Dropzone from 'react-dropzone';
import './styles.css';
import { UploadFile } from './SolutionForm';

const ACCEPTED_FILE_TYPES = { 'application/pdf': ['.pdf'] };
const MAX_FILE_SIZE_IN_MB = 20 * 1024 * 1024;

export interface SolutionFileFormProps {
    setPdfFile: (file: File | null) => void;
    pdfFile: File | null;
    pdfError: string;
    setPdfError: (error: string) => void;
    solutionUrl: string;
    setSolutionUrl: (link: string) => void;
    solutionUrlError: string;
    setSolutionUrlError: (error: string) => void;
}

const SolutionFileForm: React.FC<SolutionFileFormProps> = ({
    setPdfFile,
    pdfFile,
    pdfError,
    setPdfError,
    solutionUrl,
    setSolutionUrl,
    setSolutionUrlError,
    solutionUrlError,
}) => {
    const [noFile, setNoFile] = useState(true);
    const [uploadedFiles, setUploadedFiles] = useState<UploadFile[]>([]);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [dropZoneClass, setDropZoneClass] = useState('dropzone');

    useEffect(() => {
        setFile(pdfFile);
    }, [pdfFile]);

    const attachmentColumns = [
        {
            id: 'name',
            header: 'Attachment',
            cell: (item) => item.name,
        },
        {
            id: 'status',
            header: 'Status',
            cell: (item) => item.status,
        },
        {
            id: 'size',
            header: 'Size',
            cell: (item) => item.size,
        },
    ];

    const addSolutionFile = (file: File) => {
        if (!file) {
            setPdfError('No file.');
            return;
        }

        if (file.size > MAX_FILE_SIZE_IN_MB) {
            setPdfError('File too large.');
            return;
        }

        setButtonLoading(true);
        setPdfFile(file);
        setNoFile(false);
        setButtonLoading(false);
    };

    const onDrop = async (acceptedFiles: File[]) => {
        const file = acceptedFiles[0];
        addSolutionFile(file);
        setDropZoneClass('dropzone');
    };

    const removeSolutionFile = () => {
        setPdfFile(null);
        setNoFile(true);
    };

    const setFile = (file: File | null) => {
        if (file) {
            const size = file.size / 1024 / 1024;
            let fixedSize = size.toFixed(2);
            fixedSize = fixedSize + ' MB';
            setUploadedFiles([
                ...uploadedFiles,
                { name: file.name, status: 'Uploaded', size: fixedSize },
            ]);
        } else {
            setUploadedFiles([]);
        }
    };

    const addFile = (event) => {
        if (event.target.files) {
            addSolutionFile(event.target.files[0]);
            setPdfError('');
            event.target.value = '';
        }
    };

    const solutionUrlChange = (event): void => {
        setSolutionUrl(event.detail.value);
        setSolutionUrlError('');
    };

    return (
        <Container
            header={
                <Box>
                    <Header
                        variant="h2"
                        actions={
                            <SpaceBetween size="xs" direction="horizontal">
                                <Button
                                    disabled={noFile}
                                    onClick={removeSolutionFile}
                                >
                                    Remove
                                </Button>
                                <Button
                                    disabled={!noFile}
                                    formAction="none"
                                    onClick={() => {
                                        const fileInputElement =
                                            document.getElementById(
                                                'file-upload-input'
                                            );
                                        if (fileInputElement) {
                                            fileInputElement.click();
                                        }
                                    }}
                                >
                                    <input
                                        className="step-details-file-upload"
                                        type="file"
                                        id="file-upload-input"
                                        accept={'application/pdf'}
                                        onChange={addFile}
                                    />
                                    Add File
                                </Button>
                            </SpaceBetween>
                        }
                    >
                        File Upload
                    </Header>
                </Box>
            }
        >
            <SpaceBetween size="l">
                <FormField errorText={pdfError}>
                    <div className={dropZoneClass}>
                        <Dropzone
                            onDrop={onDrop}
                            accept={ACCEPTED_FILE_TYPES}
                            maxSize={MAX_FILE_SIZE_IN_MB}
                            multiple={false}
                            noClick={true}
                            disabled={buttonLoading}
                            onDragEnter={() => {
                                setDropZoneClass('dropzone__active');
                            }}
                            onDragLeave={() => {
                                setDropZoneClass('dropzone');
                            }}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <p>Drag 'n' drop some files here.</p>
                                </div>
                            )}
                        </Dropzone>
                    </div>
                    <div className="table">
                        <Table
                            variant="embedded"
                            items={uploadedFiles}
                            columnDefinitions={attachmentColumns}
                        />
                    </div>
                </FormField>
                <FormField label="Solution URL" errorText={solutionUrlError}>
                    <Input value={solutionUrl} onChange={solutionUrlChange} />
                </FormField>
            </SpaceBetween>
        </Container>
    );
};

export default SolutionFileForm;
