import {
    geoEnum,
    industryEnum,
    partnerTypeEnum,
    subIndustryEnum,
    subSegmentEnum,
    territoryEnum,
} from '../util/funding/FundingProgramInterface';

export const SOLUTION_DOMAINS = [
    { value: 'analytics', label: 'Analytics' },
    { value: 'iot', label: 'IoT' },
    { value: 'ml', label: 'Machine Learning' },
    { value: 'containers', label: 'Containers' },
    { value: 'nosql', label: 'Non-Relational Databases' },
    { value: 'security', label: 'Security' },
    { value: 'migrations', label: 'Migrations' },
    { value: 'chime', label: 'Chime SDK' },
    { value: 'connect', label: 'Amazon Connect' },
    { value: 'serverless', label: 'Serverless' },
    { value: 'genai', label: 'Generative AI' },
];

export interface FormOptionDefinition {
    label: string;
    value: string;
}

export interface FormOptionWithDescriptionDefinition
    extends FormOptionDefinition {
    description: string;
}

export interface SubIndustryMapping {
    industry: string;
    subIndustry: FormOptionDefinition[];
}

export const GEO: FormOptionWithDescriptionDefinition[] = [
    {
        label: 'NAMER',
        value: geoEnum.NAMER,
        description: 'Your customer is in the NAMER segment.',
    },

    {
        label: 'LATAM',
        value: geoEnum.LATAM,
        description: 'Your customer is in the LATAM segment.',
    },
    {
        label: 'EMEA',
        value: geoEnum.EMEA,
        description: 'Your customer is in the EMEA segment.',
    },
    {
        label: 'APJ',
        value: geoEnum.APJ,
        description: 'Your customer is in the APJ segment.',
    },
];

export const SUB_FIN_INDUSTRIES: FormOptionDefinition[] = [
    {
        label: 'Banking',
        value: subIndustryEnum.BNK,
    },
    {
        label: 'Capital Markets',
        value: subIndustryEnum.CAP,
    },
    {
        label: 'Insurance',
        value: subIndustryEnum.INS,
    },
    {
        label: 'Payments',
        value: subIndustryEnum.PAY,
    },
];

export const SUB_HCLS_INDUSTRIES: FormOptionDefinition[] = [
    {
        label: 'Healthcare',
        value: subIndustryEnum.HC,
    },
    {
        label: 'Life Sciences',
        value: subIndustryEnum.LS,
    },
];

export const INDUSTRY_MAPPING: SubIndustryMapping[] = [
    {
        industry: industryEnum.FIN,
        subIndustry: SUB_FIN_INDUSTRIES,
    },
    {
        industry: industryEnum.HCLS,
        subIndustry: SUB_HCLS_INDUSTRIES,
    },
];

export const ALL_SUB_INDUSTRIES = [
    ...SUB_FIN_INDUSTRIES,
    ...SUB_HCLS_INDUSTRIES,
];

export const CUSTOMER_INDUSTRY: FormOptionWithDescriptionDefinition[] = [
    {
        label: 'Advertising and Martketing',
        value: industryEnum.AD,
        description:
            'Your customer is in the advertising or marketing industry.',
    },
    {
        label: 'Software and Internet',
        value: industryEnum.SW,
        description: 'Your customer is in the software or internet industry.',
    },
    {
        label: 'Media and Enternainment',
        value: industryEnum.ME,
        description: 'Your customer is in the media or entertainment industry.',
    },
    {
        label: 'Telecommunications',
        value: industryEnum.TELE,
        description: 'Your customer is in the telecommunications industry.',
    },
    {
        label: 'Hi-Tech, Electronics, and Semiconductor',
        value: industryEnum.HT,
        description:
            'Your customer is in the hi-tech, electronics or semiconductor industry.',
    },
    {
        label: 'Manufacturing',
        value: industryEnum.MFG,
        description: 'Your customer is in the manufacturing industry.',
    },
    {
        label: 'Energy and Utilities',
        value: industryEnum.EN,
        description: 'Your customer is in the energy or utilities industry.',
    },
    {
        label: 'Financial Services',
        value: industryEnum.FIN,
        description: 'Your customer is in the financial services industry.',
    },
    {
        label: 'Healthcare and Life Sciences',
        value: industryEnum.HCLS,
        description:
            'Your customer is in the healthcare or life sciences industry.',
    },
    {
        label: 'Automotive',
        value: industryEnum.AUTO,
        description: 'Your customer is in the automotive industry.',
    },
    {
        label: 'Aerospace',
        value: industryEnum.AERO,
        description: 'Your customer is in the aerospace industry.',
    },
    {
        label: 'Travel and Hospitality',
        value: industryEnum.TRAV,
        description: 'Your customer is in the travel or hospitality industry.',
    },
    {
        label: 'Transportation and Logistics',
        value: industryEnum.TRA,
        description:
            'Your customer is in the transportation or logistics industry.',
    },
    {
        label: 'Professional Services',
        value: industryEnum.PRO,
        description: 'Your customer is in the professional services industry.',
    },
    {
        label: 'Justice and Public Safety',
        value: industryEnum.JUST,
        description:
            'Your customer is in the justice or public safety industry.',
    },
    {
        label: 'Agriculture',
        value: industryEnum.AG,
        description: 'Your customer is in the agriculture industry.',
    },
    {
        label: 'Mining',
        value: industryEnum.MIN,
        description: 'Your customer is in the mining industry.',
    },
    {
        label: 'Engineering, Construction, and Real Estate',
        value: industryEnum.RE,
        description:
            'Your customer is in the engineering, construction, or real estate industry.',
    },
    {
        label: 'Games',
        value: industryEnum.GAMES,
        description:
            'Your customer is in the telecom, media, entertainment, gaming, or sports industry.',
    },
    {
        label: 'Retail Consumer Packaged Goods',
        value: industryEnum.CPG,
        description:
            'Your customer is in the consumer packaged goods industry.',
    },
];

export const CUSTOMER_SEGMENT = [
    {
        label: 'Enterprise',
        value: industryEnum.ENT,
        description: 'Your customer is in the enterprise segment.',
    },
    {
        label: 'Digital Native Business',
        value: industryEnum.DNB,
        description: 'Your customer is in the digital native business segment.',
    },
    {
        label: 'Integrated Software Vendor',
        value: industryEnum.ISV,
        description:
            'Your customer is in the integrated software vendor segment.',
    },
    {
        label: 'Small Medium Business',
        value: industryEnum.SMB,
        description: 'Your customer is in the SMB segment.',
    },
    {
        label: 'Greenfield',
        value: industryEnum.GRN,
        description: 'Your customer is in the greenfield segment.',
    },
    {
        label: 'Startup',
        value: industryEnum.SUP,
        description: 'Your customer is in the startup segment.',
    },
];

export const SUB_SEGMENTS: FormOptionDefinition[] = [
    { value: territoryEnum.GRN, label: 'Greenfield' },
    { value: territoryEnum.ENG, label: 'Engaged' },
];

export const TERRITORY: FormOptionDefinition[] = [
    { value: subSegmentEnum.CORE, label: 'Core' },
    { value: subSegmentEnum.PRIORITY, label: 'Priority' },
];

export const WORKLOADS: FormOptionDefinition[] = [
    { value: 'migrations', label: 'Migrations' },
    { value: 'dataandanalytics', label: 'Data & Analytics' },
    { value: 'datalakesandwarehouses', label: 'Data Lakes & Data Warehouses' },
    { value: 'aiml', label: 'AI/ML' },
    { value: 'genai', label: 'Generative AI' },
    { value: 'cicd', label: 'CI/CD' },
    { value: 'devops', label: 'DevOps' },
    { value: 'iot', label: 'IoT' },
    { value: 'security', label: 'Security' },
    { value: 'compliance', label: 'Compliance' },
    { value: 'connect', label: 'Amazon Connect' },
    { value: 'cloudfront', label: 'Amazon CloudFront' },
    { value: 'chimesdk', label: 'Amazon Chime SDK' },
    { value: 'appmod', label: 'Application Modernization' },
    { value: 'database', label: 'Databases' },
    { value: 'drandbackup', label: 'Disaster Recovery & Backup' },
    { value: 'appdev', label: 'Application Development' },
    { value: 'govcloud', label: 'Gov Cloud' },
    { value: 'elemental', label: 'AWS Elemental' },
    { value: 'euc', label: 'EUC' },
    { value: 'fedramp', label: 'FedRamp' },
    { value: 'hipaa', label: 'HIPAA' },
    { value: 'hitrust', label: 'HITRUST' },
    { value: 'staffaug', label: 'Staff Augmentation' },
];

export const VERTICALS: FormOptionDefinition[] = [
    { value: 'healthcare', label: 'Health Care' },
    { value: 'lifescience', label: 'Life Science' },
    { value: 'fintech', label: 'FinTech' },
    { value: 'manufacturing', label: 'Manufacturing' },
    { value: 'isv', label: 'ISV/SaaS' },
    { value: 'mediaandentertainment', label: 'Media & Entertainment' },
    { value: 'gaming', label: 'Gaming' },
    { value: 'retail', label: 'Retail' },
];

export const AWS_PROGRAMS: FormOptionDefinition[] = [
    { value: 'msp', label: 'Managed Service Provider' },
    { value: 'resell', label: 'Resell' },
    { value: 'cppo', label: 'Consulting Partner Private Offer' },
    { value: 'wafr', label: 'Funded WAFR' },
    { value: 'saondemand', label: 'SA on Demand' },
    { value: 'immersiondays', label: 'Immersion Days' },
];

export const JUMPSTART_SERVICES_TRACKS: FormOptionDefinition[] = [
    { value: 'analytics', label: 'Analytics' },
    { value: 'iot', label: 'IoT' },
    { value: 'ml', label: 'Machine Learning' },
    { value: 'containers', label: 'Containers' },
    { value: 'nosql', label: 'Non-Relational Databases' },
];

export const WORKLOAD_TYPE: FormOptionDefinition[] = [
    { label: 'Migration', value: 'migration' },
    { label: 'Net New Workload', value: 'new' },
];

export const PARTNER_TYPES: FormOptionWithDescriptionDefinition[] = [
    {
        label: 'Services',
        value: partnerTypeEnum.SERVICES,
        description:
            'For organizations that deliver consulting, professional, managed, and value-added resale services.',
    },
    {
        label: 'Software',
        value: partnerTypeEnum.SOFTWARE,
        description:
            'For organizations that develop software that runs on or is integrated with AWS.',
    },
];

export const PARTNER_TIERS_SERVICES: FormOptionWithDescriptionDefinition[] = [
    {
        label: 'Premier Tier',
        value: 'Premier Tier',
        description: 'Premier Tier Partner',
    },
    {
        label: 'Advanced Tier',
        value: 'Advanced Tier',
        description: 'Advanced Tier Partner',
    },
    {
        label: 'Select Tier',
        value: 'Select Tier',
        description: 'Select Tier Partner',
    },
    {
        label: 'Registered Tier',
        value: 'Registered Tier',
        description: 'Registered Tier Partner',
    },
];

export const PARTNER_TIERS_SOFTWARE: FormOptionDefinition[] = [
    {
        label: 'Independent Software Vendor',
        value: 'ISV',
    },
    {
        label: 'Independent Software Vendor - Accelerate',
        value: 'ISV-A',
    },
    {
        label: 'SaaS Revenue Recognition Program',
        value: 'SRRP',
    },
];

export const YES_NO: FormOptionDefinition[] = [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },
];

export enum notificationTypeEnum {
    ADDSOLUTION,
    EDITSOLUTION,
    DELETESOLUTION,
    ADDPARTNER,
    EDITPARTNER,
    DELETEPARTNER,
}

export const S360_LINKS: FormOptionDefinition[] = [
    {
        label: 'New Funding Event - Engage to Determine Growth Plans',
        value: 'new_funding_event',
    },
    {
        label: 'New GPU Request - Engage on High Potential Workload',
        value: 'new_gpu_request',
    },
    {
        label: 'Marketplace Private Offer Expiration - Engage to Renew Commitment',
        value: 'marketplace_private_offer_expiration',
    },
    {
        label: 'Bedrock Fast Mover Up - Engage to Accelerate Growth',
        value: 'bedrock_fast_mover_up',
    },
    {
        label: 'Bedrock Revenue Decline - Engage to Avoid Attrition',
        value: 'bedrock_revenue_decline',
    },
    {
        label: 'Activate Credit Exhaustion- Engage to Transition off Credits',
        value: 'activate_credit_exhaustion',
    },
    {
        label: 'Activate Credit Expiration - Engage to Transition off Credits',
        value: 'activate_credit_expiration',
    },
    {
        label: 'Activate Change - New Credits - Engage to Understand Roadmap',
        value: 'activate_change',
    },
    {
        label: 'Activate Slow Burner - Engage to Accelerate Usage',
        value: 'activate_slow_burner',
    },
    {
        label: 'Tier 1 Activate Fast Burner -  Engage to Transition off Credits',
        value: 'tier_1_activate_fast_burner',
    },
    {
        label: 'Tier 2 Activate Fast Burner -  Engage to Transition off Credits',
        value: 'tier_2_activate_fast_burner',
    },
    {
        label: 'Activate Change - Top Up Credits - Engage to Understand Roadmap',
        value: 'activate_change',
    },
    {
        label: 'New Account ID Created - Potential Experimentation',
        value: 'new_account_id_created',
    },
    {
        label: 'Significant Revenue Decline - Engage to Avoid Attrition',
        value: 'significat_revenue_decline',
    },
    {
        label: 'Fast Mover Up - Engage to Accelerate Growth',
        value: 'fast_mover_up',
    },
    {
        label: 'EDP Eligible - Engage to Secure Commitment',
        value: 'edp_eligible',
    },
    { label: 'EDP Target - Engage to Secure Commitment', value: 'edp_target' },
    {
        label: 'Previous Closed Lost Migration Oppty - Consider New Outreach',
        value: 'previous_closed_lost_migration_opp',
    },
    {
        label: 'Net-New Charger - Engage to Accelerate Growth',
        value: 'net_new_charger',
    },
    {
        label: 'High Charger x No Activity - Engage to Maintain Trust',
        value: 'high_charger_x_no_activity',
    },
    {
        label: 'Unengaged X Significant Charger - Engage to Accelerate Growth',
        value: 'unengaged_x_significant_charger',
    },
    {
        label: 'Spend Pattern Indicates External DB Workloads',
        value: 'spend_pattern_indicates_external_db_workloads',
    },
    {
        label: 'Spend Pattern Indicates External Compute Workloads',
        value: 'spend_pattern_indicates_external_compute_workloads',
    },
    {
        label: 'High Migration Propensity Score',
        value: 'high_migration_propensity_score',
    },
    {
        label: 'Competitive Cloud Adoption',
        value: 'competitive_cloud_adoption',
    },
    { label: 'Aurora - Graviton Optimization Opportunity', value: 'aurora' },
    {
        label: 'CloudFront PPA Target - Engage to Secure Commitment',
        value: 'cloudfront_ppa_target',
    },
    {
        label: 'CloudWatch PPA Target - Engage to Secure Commitment',
        value: 'cloudwatch_ppa_target',
    },
    {
        label: 'Compute Revenue Optimization Opportunity for AMD-Graviton: upgrade to updated Graviton Type',
        value: 'compute_opp_amd_graviton',
    },
    {
        label: 'Compute Revenue Optimization Opportunity for EC2: upgrade to Savings Plan',
        value: 'compute_opp_ec2_savings_plan',
    },
    {
        label: 'Compute Revenue Optimization Opportunity for EC2: upgrade to SPOT / Savings Plan',
        value: 'compute_opp_ec2_spot_savings_plan',
    },
    {
        label: 'Compute Revenue Optimization Opportunity for Fargate: upgrade to SPOT / Savings Plan',
        value: 'compute_opp_fargate_spot_savings_plan',
    },
    {
        label: 'Compute Revenue Optimization Opportunity for Graviton: upgrade to updated Graviton Type',
        value: 'compute_opp_graviton_update',
    },
    {
        label: 'Compute Revenue Optimization Opportunity for Instance: upgrade to updated Instance Type',
        value: 'compute_opp_instance_update',
    },
    {
        label: 'Compute Revenue Optimization Opportunity for Lambda: upgrade to SPOT / Savings Plan',
        value: 'compute_opp_lambda_spot_savings_plan',
    },
    {
        label: 'DataTransfer PPA Target - Engage to Secure Commitment',
        value: 'datatransfer_ppa_target',
    },
    {
        label: 'EC2 - Graviton Optimization Opportunity',
        value: 'ec2_graviton_optimization',
    },
    {
        label: 'EFS PPA Target - Engage to Secure Commitment',
        value: 'efs_ppa_target',
    },
    {
        label: 'ElastiCache - Graviton Optimization Opportunity',
        value: 'elasticache_graviton_optimization',
    },
    {
        label: 'ElasticMapReduce - Graviton Optimization Opportunity',
        value: 'emr_graviton_optimization',
    },
    {
        label: 'ElementalMediaPackage PPA Target - Engage to Secure Commitment',
        value: 'elemental_media_package_ppa_target',
    },
    {
        label: 'ElementalMediaTailor PPA Target - Engage to Secure Commitment',
        value: 'elemental_media_tailor_ppa_target',
    },
    {
        label: 'FSx PPA Target - Engage to Secure Commitment',
        value: 'fsx_ppa_target',
    },
    {
        label: 'Graviton Fast Mover Down - Engage to Retain Revenue',
        value: 'graviton_fast_mover_down',
    },
    {
        label: 'Graviton Fast Mover Down Projected - Engage to Retain Revenue',
        value: 'graviton_fast_mover_down_projected',
    },
    {
        label: 'Graviton Fast Mover Up - Engage to Accelerate Growth',
        value: 'graviton_fast_mover_up',
    },
    {
        label: 'Graviton Fast Mover Up Projected - Engage to Accelerate Growth',
        value: 'graviton_fast_mover_up_projected',
    },
    {
        label: 'Marketplace Private Offer - First Time Subscribers - Engage for AWS-MP Commitments',
        value: 'marketplace_private_offer_first_time',
    },
    {
        label: 'Marketplace Private Offer - Further Subscriptions - Engage to Expand AWS-MP Commitments',
        value: 'marketplace_private_offer_further_subscriptions',
    },
    {
        label: 'Net-New SFDC Customer - Engage to Understand Roadmap',
        value: 'net_new_sfdc_customer',
    },
    {
        label: 'New -AI/ML - Bedrock Adoption - Engage to Grow Customer Usage',
        value: 'new_ai_ml_bedrock_adoption',
    },
    {
        label: 'New -AI/ML - Rekognition Adoption - Engage to Grow Customer Usage',
        value: 'new_ai_ml_rekognition_adoption',
    },
    {
        label: 'New -AI/ML - SageMaker Adoption - Engage to Grow Customer Usage',
        value: 'new_ai_ml_sagemaker_adoption',
    },
    {
        label: 'New -AI/ML - Textract Adoption - Engage to Grow Customer Usage',
        value: 'new_ai_ml_textract_adoption',
    },
    {
        label: 'New -AI/ML - transcribe Adoption - Engage to Grow Customer Usage',
        value: 'new_ai_ml_transcribe_adoption',
    },
    {
        label: 'New -Analytics - Amazon Q Adoption - Engage to Grow Customer Usage',
        value: 'new_analytics_amazon_q_adoption',
    },
    {
        label: 'New -Analytics - ElasticMapReduce Adoption - Engage to Grow Customer Usage',
        value: 'new_analytics_emr_adoption',
    },
    {
        label: 'New -Analytics - ES Adoption - Engage to Grow Customer Usage',
        value: 'new_analytics_es_adoption',
    },
    {
        label: 'New -Analytics - Glue Adoption - Engage to Grow Customer Usage',
        value: 'new_analytics_glue_adoption',
    },
    {
        label: 'New -Analytics - Kinesis Adoption - Engage to Grow Customer Usage',
        value: 'new_analytics_kinesis_adoption',
    },
    {
        label: 'New -Analytics - QuickSight Adoption - Engage to Grow Customer Usage',
        value: 'new_analytics_quicksight_adoption',
    },
    {
        label: 'New -Analytics - Redshift Adoption - Engage to Grow Customer Usage',
        value: 'new_analytics_redshift_adoption',
    },
    {
        label: 'New -Containers & Serverless - EKS Adoption - Engage to Grow Customer Usage',
        value: 'new_containers_serverless_eks_adoption',
    },
    {
        label: 'New -Containers & Serverless - Fargate Adoption - Engage to Grow Customer Usage',
        value: 'new_containers_serverless_fargate_adoption',
    },
    {
        label: 'New -Containers & Serverless - Lambda Adoption - Engage to Grow Customer Usage',
        value: 'new_containers_serverless_lambda_adoption',
    },
    {
        label: 'New -Database - DocDB Adoption - Engage to Grow Customer Usage',
        value: 'new_database_docdb_adoption',
    },
    {
        label: 'New -Database - DynamoDB Adoption - Engage to Grow Customer Usage',
        value: 'new_database_dynamodb_adoption',
    },
    {
        label: 'New -Database - ElastiCache Adoption - Engage to Grow Customer Usage',
        value: 'new_database_elasticache_adoption',
    },
    {
        label: 'New -Database - RDS Adoption - Engage to Grow Customer Usage',
        value: 'new_database_rds_adoption',
    },
    {
        label: 'New Graviton Adoption in EC2 - Engage to Maintain Usage',
        value: 'new_graviton_adoption_ec2',
    },
    {
        label: 'New P Instance Adoption in EC2 - Engage to Maintain Usage',
        value: 'new_p_instance_adoption_ec2',
    },
    {
        label: 'New Support Ticket - Engage to Ensure Resolution',
        value: 'new_support_ticket',
    },
    {
        label: 'Non-EC2 Revenue Optimization Opportunity for Elasticache: upgrade to Reserved Instance Pricing',
        value: 'non_ec2_opp_elasticache_ri',
    },
    {
        label: 'Non-EC2 Revenue Optimization Opportunity for Elastisearch: upgrade to Reserved Instance Pricing',
        value: 'non_ec2_opp_elasticsearch_ri',
    },
    {
        label: 'Non-EC2 Revenue Optimization Opportunity for RDS: upgrade to Reserved Instance Pricing',
        value: 'non_ec2_opp_rds_ri',
    },
    {
        label: 'Non-EC2 Revenue Optimization Opportunity for Redshift: upgrade to Reserved Instance Pricing',
        value: 'non_ec2_opp_redshift_ri',
    },
    {
        label: 'RDS - Graviton Optimization Opportunity',
        value: 'rds_graviton_optimization',
    },
    {
        label: 'Rekognition PPA Target - Engage to Secure Commitment',
        value: 'rekognition_ppa_target',
    },
    { label: 'RI Expiring - Engage to Renew Commitment', value: 'ri_expiring' },
    {
        label: 'RI Purchased - Engage to Accelerate Usage',
        value: 'ri_purchased',
    },
    {
        label: 'S3 PPA Target - Engage to Secure Commitment',
        value: 's3_ppa_target',
    },
    {
        label: 'SageMaker - Graviton Optimization Opportunity',
        value: 'sagemaker_graviton_optimization',
    },
    {
        label: 'SageMaker PPA Target - Engage to Secure Commitment',
        value: 'sagemaker_ppa_target',
    },
    {
        label: 'Security Risk - Engage to Improve Posture',
        value: 'security_risk',
    },
    {
        label: 'SES PPA Target - Engage to Secure Commitment',
        value: 'ses_ppa_target',
    },
    { label: 'SP Expiring - Engage to Renew Commitment', value: 'sp_expiring' },
    {
        label: 'SP Purchased - Engage to Accelerate Usage',
        value: 'sp_purchased',
    },
    {
        label: 'Storage Revenue Optimization Opportunity for EBS: upgrade volume type',
        value: 'storage_opp_ebs_upgrade',
    },
    {
        label: 'Storage Revenue Optimization Opportunity for S3: upgrade storage class',
        value: 'storage_opp_s3_upgrade',
    },
    {
        label: 'SupportEnterprise PPA Target - Engage to Secure Commitment',
        value: 'support_enterprise_ppa_target',
    },
];
