import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    Form,
    Header,
    SpaceBetween,
} from '@amzn/awsui-components-react';

import SolutionDetailsForm, {
    SolutionDetailsFormProps,
} from './SolutionDetailsForm';

import SolutionPartnerForm, {
    SolutionPartnerFormProps,
} from './SolutionPartnerForm';
import SolutionTagsForm, { SolutionTagsFormProps } from './SolutionTagsForm';
import SolutionFileForm, { SolutionFileFormProps } from './SolutionFileForm';

import {
    setNotification,
    useAddSolutionMutation,
    useEditSolutionMutation,
    useFetchSolutionQuery,
} from '../../../store';
import { checkIfAdmin } from '../../../util/AuthService';
import { storeFile } from '../../../util/SolutionFormFunctions';
import { SolutionDefinition } from '../../../interfaces/solution.interface';
import { skipToken } from '@reduxjs/toolkit/query';
import { useDispatch } from 'react-redux';
import {
    CUSTOMER_INDUSTRY,
    INDUSTRY_MAPPING,
    notificationTypeEnum,
} from '../../../constants/Constants';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { mapArrayToOptions } from '../../../util/MapOptions';

export interface UploadFile {
    name: string;
    status: string;
    size: string;
}

interface SolutionFormProps {
    editMode: boolean;
}

const SolutionForm: React.FC<SolutionFormProps> = ({ editMode }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let id: number | null = null;
    if (editMode) {
        const pathName = window.location.pathname;
        id = parseInt(pathName.substring(pathName.lastIndexOf('/') + 1));
    }

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [geos, setGeos] = useState<string[]>([]);
    const [segments, setSegments] = useState<string[]>([]);
    const [industries, setIndustries] = useState<string[]>([]);
    const [subIndustries, setSubIndustries] = useState<string[]>([]);
    const [domain, setDomain] = useState('');
    const [s360, setS360] = useState<string[]>([]);
    const [hasMarketplace, setHasMarketplace] = useState(false);
    const [marketplaceUrl, setMarketplaceUrl] = useState('');
    const [hasBoost, setHasBoost] = useState(false);
    const [boostUrls, setBoostUrls] = useState<string[]>([]);

    const [partner, setPartner] = useState('');
    const [partnerContactName, setPartnerContactName] = useState('');
    const [partnerContactEmail, setPartnerContactEmail] = useState('');

    const [tags, setTags] = useState<string[]>([]);

    const [pdfFile, setPdfFile] = useState<File | null>(null);

    const [solutionUrl, setSolutionUrl] = useState<string>('');
    const [solutionUrlError, setSolutionUrlError] = useState<string>('');

    const [titleError, setTitleError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [geoError, setGeoError] = useState('');
    const [segmentError, setSegmentError] = useState('');
    const [industryError, setIndustryError] = useState('');
    const [subIndustryError, setSubIndustryError] = useState('');
    const [domainError, setDomainError] = useState('');
    const [marketplaceUrlError, setMarketplaceUrlError] = useState('');
    const [boostUrlError, setBoostUrlError] = useState('');

    const [subIndustryOptions, setSubIndustryOptions] = useState<
        OptionDefinition[]
    >([]);

    const [partnerError, setPartnerError] = useState('');
    const [partnerContactNameError, setPartnerContactNameError] = useState('');
    const [partnerContactEmailError, setPartnerContactEmailError] =
        useState('');

    const [pdfError, setPdfError] = useState('');

    const [savingSolution, setSavingSolution] = React.useState(false);

    const { data: solution } = useFetchSolutionQuery(id ? id : skipToken);

    const [addSolution, addSolutionResult] = useAddSolutionMutation();
    const [editSolution, editSolutionResult] = useEditSolutionMutation();

    useEffect(() => {
        if (!checkIfAdmin()) {
            navigate('/');
        }
    }, []);

    useEffect(() => {
        if (editMode && solution) {
            setTitle(solution.title || '');
            setDescription(solution.description || '');
            setPartnerContactName(solution.partnerContactName || '');
            setPartnerContactEmail(solution.partnerContactEmail || '');
            setGeos(solution.geos || []);
            setSegments(solution.segments || []);
            setSubIndustries(solution.subIndustries || []);
            setIndustries(solution.industries || []);
            setDomain(solution.domain || '');
            setS360(solution.s360 || '');
            setHasMarketplace(solution.hasMarketplace || false);
            setMarketplaceUrl(solution.marketplaceUrl || '');
            setHasBoost(solution.hasBoost || false);
            setBoostUrls(solution.boostUrls || []);
            setPartner(solution.partner || '');
            setTags(solution.tags || []);
            setSolutionUrl(solution.solutionUrl || '');
        }
    }, [solution]);

    useEffect(() => {
        let options: OptionDefinition[] = [];
        const industryOptions = mapArrayToOptions(
            industries,
            CUSTOMER_INDUSTRY
        );
        industryOptions.forEach((industry) => {
            const industryMap = INDUSTRY_MAPPING.find(
                (element) => industry.value === element.industry
            );
            if (industryMap) {
                industryMap.subIndustry.forEach((subIndustry) => {
                    options.push(subIndustry);
                });
            }
        });
        setSubIndustryOptions(options);
    }, [industries]);

    const solutionDetailsProps: SolutionDetailsFormProps = {
        title,
        description,
        geos,
        segments,
        industries,
        subIndustries,
        subIndustryOptions,
        domain,
        s360,
        hasMarketplace,
        marketplaceUrl,
        hasBoost,
        boostUrls,
        setTitle,
        setDescription,
        setGeos,
        setSegments,
        setIndustries,
        setSubIndustries,
        setDomain,
        setHasMarketplace,
        setMarketplaceUrl,
        setHasBoost,
        setBoostUrls,
        titleError,
        descriptionError,
        geoError,
        segmentError,
        industryError,
        subIndustryError,
        domainError,
        marketplaceUrlError,
        boostUrlError,
        setTitleError,
        setDescriptionError,
        setGeoError,
        setSegmentError,
        setIndustryError,
        setSubIndustryError,
        setDomainError,
        setS360,
        setMarketplaceUrlError,
        setBoostUrlError,
    };

    const solutionPartnerProps: SolutionPartnerFormProps = {
        partner,
        setPartner,
        partnerError,
        setPartnerError,
        partnerContactName,
        setPartnerContactName,
        partnerContactNameError,
        setPartnerContactNameError,
        partnerContactEmail,
        setPartnerContactEmail,
        partnerContactEmailError,
        setPartnerContactEmailError,
    };

    const solutionTagsProps: SolutionTagsFormProps = {
        tags,
        setTags,
    };

    const solutionFileProps: SolutionFileFormProps = {
        setPdfFile,
        pdfFile,
        pdfError,
        setPdfError,
        solutionUrl,
        setSolutionUrl,
        solutionUrlError,
        setSolutionUrlError,
    };

    const solutionForm: SolutionDefinition = {
        partnerContactEmail,
        partnerContactName,
        newFileFlag: false,
        title,
        description,
        geos,
        segments,
        industries,
        subIndustries,
        domain,
        s360,
        hasMarketplace,
        marketplaceUrl,
        hasBoost,
        boostUrls,
        partner,
        tags,
        solutionUrl,
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        setSavingSolution(true);
        if (validateSolutionForm()) {
            setSavingSolution(false);
            return;
        }
        if (!editMode) {
            createNewSolution();
        } else {
            if (pdfFile) {
                solutionForm.newFileFlag = true;
            }
            if (solution) {
                solutionForm.createdAt = solution.createdAt;
            }
            editThisSolution();
        }
    };

    const editThisSolution = () => {
        editSolution({ solution: solutionForm, solutionId: id || 0 })
            .unwrap()
            .then(async (response) => {
                if (pdfFile) {
                    try {
                        await storeFile(response.url, pdfFile);
                    } catch (error) {
                        console.error(error);
                        setSavingSolution(false);
                        dispatch(
                            setNotification({
                                content: 'Error updating solution.',
                                eventType: 'error',
                                notificationType:
                                    notificationTypeEnum.EDITSOLUTION,
                            })
                        );
                        window.scrollTo(0, 0);
                    }
                }
            })
            .then(() => {
                dispatch(
                    setNotification({
                        content: 'Solution updated successfully.',
                        eventType: 'success',
                        notificationType: notificationTypeEnum.EDITSOLUTION,
                    })
                );
                navigate(-1);
            })
            .catch((error) => {
                console.error(error);
                setSavingSolution(false);
                dispatch(
                    setNotification({
                        content: 'Error updating solution.',
                        eventType: 'error',
                        notificationType: notificationTypeEnum.EDITSOLUTION,
                    })
                );
                window.scrollTo(0, 0);
            });
    };
    const createNewSolution = () => {
        if (pdfFile || solutionUrl) {
            addSolution(solutionForm)
                .unwrap()
                .then(async (response) => {
                    if (pdfFile && !solutionUrl) {
                        try {
                            await storeFile(response.url, pdfFile);
                        } catch (error) {
                            console.error(error);
                            setSavingSolution(false);
                            dispatch(
                                setNotification({
                                    content: 'Error creating solution.',
                                    eventType: 'error',
                                    notificationType:
                                        notificationTypeEnum.ADDSOLUTION,
                                })
                            );
                        }
                    }
                    window.scrollTo(0, 0);
                })
                .then(() => {
                    resetState();
                    window.scrollTo(0, 0);
                    dispatch(
                        setNotification({
                            content: 'Solution created successfully.',
                            eventType: 'success',
                            notificationType: notificationTypeEnum.ADDSOLUTION,
                        })
                    );
                    setSavingSolution(false);
                    navigate('/');
                })
                .catch((error) => {
                    console.error(error);
                    setSavingSolution(false);
                    dispatch(
                        setNotification({
                            content: 'Error creating solution.',
                            eventType: 'error',
                            notificationType: notificationTypeEnum.ADDSOLUTION,
                        })
                    );
                    window.scrollTo(0, 0);
                });
        }
    };

    const validateSolutionForm = (): boolean => {
        let formErrors = false;
        if (!solutionForm.title) {
            setTitleError('Please enter a title.');
            formErrors = true;
        }
        if (!solutionForm.description) {
            setDescriptionError('Please enter a description.');
            formErrors = true;
        }
        if (Object.keys(solutionForm.partner).length === 0) {
            setPartnerError('Please enter a partner.');
            formErrors = true;
        }
        if (!solutionForm.marketplaceUrl && solutionForm.hasMarketplace) {
            setMarketplaceUrlError('Please enter a marketplace URL.');
            formErrors = true;
        }
        if (!solutionForm.boostUrls && solutionForm.hasBoost) {
            setBoostUrlError('Please enter a boost URL.');
            formErrors = true;
        }
        if (Object.keys(solutionForm.domain).length === 0) {
            setDomainError('Please enter a domain.');
            formErrors = true;
        }
        if (solutionForm.segments.length < 1) {
            setSegmentError('Please enter at least one segment.');
            formErrors = true;
        }
        if (solutionForm.geos.length < 1) {
            setGeoError('Please enter at least one geo.');
            formErrors = true;
        }
        if (solutionForm.partner.length < 1) {
            setPartnerError('Please enter a partner.');
            formErrors = true;
        }
        if (!pdfFile && !editMode && !solutionUrl) {
            setPdfError('Please select a file or add an URL.');
            setSolutionUrlError('Please select a file or add an URL.');
            formErrors = true;
        }

        return formErrors;
    };

    const stringStateFunctions = [
        setTitle,
        setDescription,
        setDomain,
        setMarketplaceUrl,
        setPartner,
        setTitleError,
        setDescriptionError,
        setDomainError,
        setMarketplaceUrlError,
        setBoostUrlError,
        setPartnerError,
        setSegmentError,
        setGeoError,
        setPdfError,
    ];

    const arrayStateFunctions = [setGeos, setSegments, setTags, setBoostUrls];

    const booleanStateFunctions = [setHasMarketplace, setHasBoost];
    const resetState = () => {
        stringStateFunctions.forEach((func) => func(''));
        arrayStateFunctions.forEach((func) => func([]));
        booleanStateFunctions.forEach((func) => func(false));
        setPdfFile(null);
    };

    const cancelEvent = () => {
        resetState();
        if (editMode) {
            navigate(-1);
        } else {
            window.scrollTo(0, 0);
        }
    };

    const isLoading =
        addSolutionResult.isLoading ||
        savingSolution ||
        editSolutionResult.isLoading;

    return (
        <div>
            <form onSubmit={onSubmit}>
                <Form
                    header={
                        <Header variant="h1">
                            {editMode ? 'Edit' : 'Add'} Partner Solution
                        </Header>
                    }
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button
                                formAction="none"
                                variant="link"
                                onClick={cancelEvent}
                            >
                                Cancel
                            </Button>
                            <Button variant="primary" loading={isLoading}>
                                Submit
                            </Button>
                        </SpaceBetween>
                    }
                >
                    <SpaceBetween direction="vertical" size="l">
                        <SolutionDetailsForm {...solutionDetailsProps} />
                        <SolutionPartnerForm {...solutionPartnerProps} />
                        <SolutionTagsForm {...solutionTagsProps} />
                        <SolutionFileForm {...solutionFileProps} />
                    </SpaceBetween>
                </Form>
            </form>
        </div>
    );
};

export default SolutionForm;
